var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("vx-card", { attrs: { title: "Flex alignment" } }, [
    _c("p", [
      _vm._v("To align the elements vertically we have the directive "),
      _c("code", [_vm._v("vs-align")]),
      _vm._v(" that as parameters the same known values of css: "),
      _c("code", [_vm._v("center")]),
      _vm._v(", "),
      _c("code", [_vm._v("flex-end")]),
      _vm._v(", "),
      _c("code", [_vm._v("flex-start")]),
      _vm._v("."),
    ]),
    _c(
      "div",
      { staticClass: "mt-5" },
      [
        _c("flex-alignment-top"),
        _c("flex-alignment-center"),
        _c("flex-alignment-bottom"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }