var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Responsive layout", "code-toggler": "" } },
    [
      _c("p", { staticClass: "mb-5" }, [
        _vm._v(
          "There are some measures that can only be added in a specific size of the device, the directives are:"
        ),
      ]),
      _c("vx-list", { attrs: { list: _vm.list } }),
      _c(
        "div",
        { staticClass: "grid-demo__layout-container" },
        [
          _c(
            "vs-row",
            { attrs: { "vs-w": "12" } },
            [
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-lg": "2",
                    "vs-sm": "4",
                    "vs-xs": "12",
                  },
                },
                [_vm._v('\n                    vs-lg="2" ')]
              ),
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-lg": "8",
                    "vs-sm": "4",
                    "vs-xs": "12",
                  },
                },
                [_vm._v('\n                    vs-lg="8" ')]
              ),
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-lg": "2",
                    "vs-sm": "4",
                    "vs-xs": "12",
                  },
                },
                [_vm._v('\n                    vs-lg="2" ')]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n\n<vs-row vs-w="12">\n  <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="2" vs-sm="4" vs-xs="12" >\n    vs-lg="2" <!-- vs-sm="4" vs-xs="12" -->\n  </vs-col>\n  <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="4" vs-xs="12">\n    vs-lg="8" <!-- vs-sm="4" vs-xs="12" -->\n  </vs-col>\n  <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="2" vs-sm="4" vs-xs="12">\n    vs-lg="2" <!-- vs-sm="4" vs-xs="12" -->\n  </vs-col>\n</vs-row>\n\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }