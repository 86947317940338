var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Column offset", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "To give a distance with respect to the left we have the directive vs-offset that with the same measures 1-12 we add the space specified a serious example "
        ),
        _c("code", [_vm._v("12 = 100%")]),
        _vm._v(", "),
        _c("code", [_vm._v("6 = 50%")]),
        _vm._v(", "),
        _c("code", [_vm._v("4 = 25%")]),
      ]),
      _c(
        "div",
        { staticClass: "grid-demo__layout-container" },
        [
          _c(
            "vs-row",
            { attrs: { "vs-w": "12" } },
            [
              _c(
                "vs-col",
                {
                  staticClass: "sm:p-2 p-4",
                  attrs: {
                    "vs-offset": "5",
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "6",
                  },
                },
                [
                  _c("span", { staticClass: "sm:inline hidden" }, [
                    _vm._v("offset - 6"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "vs-row",
            [
              _c(
                "vs-col",
                {
                  staticClass: "sm:p-2 p-4",
                  attrs: {
                    "vs-offset": "0",
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "2",
                  },
                },
                [
                  _c("span", { staticClass: "sm:inline hidden" }, [
                    _vm._v("offset - 2"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "vs-row",
            [
              _c(
                "vs-col",
                {
                  staticClass: "sm:p-2 p-4",
                  attrs: {
                    "vs-offset": "2",
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "8",
                  },
                },
                [
                  _c("span", { staticClass: "sm:inline hidden" }, [
                    _vm._v("offset - 8"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "vs-row",
            [
              _c(
                "vs-col",
                {
                  staticClass: "sm:p-2 p-4",
                  attrs: {
                    "vs-offset": "10",
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "2",
                  },
                },
                [
                  _c("span", { staticClass: "sm:inline hidden" }, [
                    _vm._v("offset - 7"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "vs-row",
            [
              _c(
                "vs-col",
                {
                  staticClass: "sm:p-2 p-4",
                  attrs: {
                    "vs-offset": "4",
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "4",
                  },
                },
                [
                  _c("span", { staticClass: "sm:inline hidden" }, [
                    _vm._v("offset - 4"),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<vs-row vs-w="12">\n  <vs-col vs-offset="5" vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">\n    offset - 6\n  </vs-col>\n</vs-row>\n\n<vs-row>\n  <vs-col vs-offset="0" v-tooltip="\'col - 2\'" vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">\n    offset - 2\n  </vs-col>\n</vs-row>\n\n<vs-row>\n  <vs-col vs-offset="2" v-tooltip="\'col - 8\'" vs-type="flex" vs-justify="center" vs-align="center" vs-w="8">\n    offset - 8\n  </vs-col>\n</vs-row>\n\n<vs-row>\n  <vs-col vs-offset="10" v-tooltip="\'col - 1\'" vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">\n    offset - 7\n  </vs-col>\n</vs-row>\n\n<vs-row>\n  <vs-col vs-offset="4" v-tooltip="\'col - 4\'" vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">\n    offset - 4\n  </vs-col>\n</vs-row>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }