var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Design Concept", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("With the directive "),
        _c("code", [_vm._v("vs-w")]),
        _vm._v(" define the width of the column ("),
        _c("code", [_vm._v("vs-col")]),
        _vm._v(") its value is "),
        _c("strong", [_vm._v("1-12")]),
        _vm._v(" an example of sizes would be: "),
        _c("strong", [_vm._v("12 = 100%, 6 = 50%, 4 = 25%")]),
      ]),
      _c(
        "div",
        { staticClass: "grid-demo__layout-container" },
        [
          _c(
            "vs-row",
            [
              _c(
                "vs-col",
                {
                  staticClass: "p-4 sm:p-2",
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "12",
                  },
                },
                [
                  _c("span", { staticClass: "sm:inline hidden" }, [
                    _vm._v("100%"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "vs-row",
            _vm._l(2, function (col, index) {
              return _c(
                "vs-col",
                {
                  key: index,
                  staticClass: "p-4 sm:p-2",
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "6",
                  },
                },
                [
                  _c("span", { staticClass: "sm:inline hidden" }, [
                    _vm._v("50%"),
                  ]),
                ]
              )
            }),
            1
          ),
          _c(
            "vs-row",
            _vm._l(3, function (col, index) {
              return _c(
                "vs-col",
                {
                  key: index,
                  staticClass: "p-4 sm:p-2",
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "4",
                  },
                },
                [
                  _c("span", { staticClass: "sm:inline hidden" }, [
                    _vm._v("33.3%"),
                  ]),
                ]
              )
            }),
            1
          ),
          _c(
            "vs-row",
            _vm._l(4, function (col, index) {
              return _c(
                "vs-col",
                {
                  key: index,
                  staticClass: "p-4 sm:p-2",
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "3",
                  },
                },
                [
                  _c("span", { staticClass: "sm:inline hidden" }, [
                    _vm._v("25%"),
                  ]),
                ]
              )
            }),
            1
          ),
          _c(
            "vs-row",
            _vm._l(6, function (col, index) {
              return _c(
                "vs-col",
                {
                  key: index,
                  staticClass: "p-4 sm:p-2",
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "2",
                  },
                },
                [
                  _c("span", { staticClass: "sm:inline hidden" }, [
                    _vm._v("16.6%"),
                  ]),
                ]
              )
            }),
            1
          ),
          _c(
            "vs-row",
            _vm._l(12, function (col, index) {
              return _c(
                "vs-col",
                {
                  key: index,
                  staticClass: "p-4 sm:p-2",
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "1",
                  },
                },
                [
                  _c("span", { staticClass: "sm:inline hidden" }, [
                    _vm._v("8.3%"),
                  ]),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<vs-row>\n  <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">\n    100%\n  </vs-col>\n</vs-row>\n\n<vs-row>\n  <vs-col :key="index" v-for="col,index in 2" vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">\n    50%\n  </vs-col>\n</vs-row>\n\n<vs-row>\n  <vs-col :key="index" v-for="col,index in 3" v-tooltip="\'col - 4\'" vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">\n    33.3%\n  </vs-col>\n</vs-row>\n\n<vs-row>\n  <vs-col :key="index" v-for="col,index in 4" v-tooltip="\'col - 3\'" vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">\n    25%\n  </vs-col>\n</vs-row>\n\n<vs-row>\n  <vs-col :key="index" v-for="col,index in 6" v-tooltip="\'col - 2\'" vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">\n    16.6%\n  </vs-col>\n</vs-row>\n\n<vs-row>\n  <vs-col :key="index" v-for="col,index in 12" v-tooltip="\'col - 1\'" vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">\n    8.3%\n  </vs-col>\n</vs-row>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }