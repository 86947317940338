var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Flex layout", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("If we need to align the elements horizontally, use the "),
        _c("code", [_vm._v("vs-justify")]),
        _vm._v(" directive that uses CSS attributes as parameters: "),
        _c("code", [_vm._v("flex-start")]),
        _vm._v(", "),
        _c("code", [_vm._v("center")]),
        _vm._v(", "),
        _c("code", [_vm._v("flex-end")]),
        _vm._v(", "),
        _c("code", [_vm._v("space-around")]),
        _vm._v(", "),
        _c("code", [_vm._v("space-between")]),
      ]),
      _c(
        "div",
        { staticClass: "grid-demo__layout-container" },
        [
          _c(
            "vs-row",
            { attrs: { "vs-w": "12" } },
            _vm._l(3, function (col, index) {
              return _c(
                "vs-col",
                {
                  key: index,
                  staticClass: "sm:p-0 p-4 text-center",
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "2",
                  },
                },
                [
                  _c("span", { staticClass: "sm:inline hidden" }, [
                    _vm._v("default"),
                  ]),
                ]
              )
            }),
            1
          ),
          _c(
            "vs-row",
            { attrs: { "vs-type": "flex", "vs-justify": "center" } },
            _vm._l(3, function (col, index) {
              return _c(
                "vs-col",
                {
                  key: index,
                  staticClass: "sm:p-0 p-4 text-center",
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "2",
                  },
                },
                [
                  _c("span", { staticClass: "sm:inline hidden" }, [
                    _vm._v("center"),
                  ]),
                ]
              )
            }),
            1
          ),
          _c(
            "vs-row",
            { attrs: { "vs-type": "flex", "vs-justify": "flex-end" } },
            _vm._l(3, function (col, index) {
              return _c(
                "vs-col",
                {
                  key: index,
                  staticClass: "sm:p-0 p-4 text-center",
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "2",
                  },
                },
                [
                  _c("span", { staticClass: "sm:inline hidden" }, [
                    _vm._v("flex-end"),
                  ]),
                ]
              )
            }),
            1
          ),
          _c(
            "vs-row",
            { attrs: { "vs-type": "flex", "vs-justify": "space-around" } },
            _vm._l(3, function (col, index) {
              return _c(
                "vs-col",
                {
                  key: index,
                  staticClass: "sm:p-0 p-4 text-center",
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "2",
                  },
                },
                [
                  _c("span", { staticClass: "sm:inline hidden" }, [
                    _vm._v("space-around"),
                  ]),
                ]
              )
            }),
            1
          ),
          _c(
            "vs-row",
            { attrs: { "vs-type": "flex", "vs-justify": "space-between" } },
            _vm._l(3, function (col, index) {
              return _c(
                "vs-col",
                {
                  key: index,
                  staticClass: "sm:p-0 p-4 text-center",
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "2",
                  },
                },
                [
                  _c("span", { staticClass: "sm:inline hidden" }, [
                    _vm._v("space-between"),
                  ]),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<vs-row vs-w="12">\n  <vs-col :key="index" v-for="col,index in 3" vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">\n    default\n  </vs-col>\n</vs-row>\n\n<vs-row vs-type="flex" vs-justify="center">\n  <vs-col :key="index" v-for="col,index in 3" vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">\n    center\n  </vs-col>\n</vs-row>\n\n<vs-row vs-type="flex" vs-justify="flex-end">\n  <vs-col :key="index" v-for="col,index in 3" vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">\n    flex-end\n  </vs-col>\n</vs-row>\n\n\n<vs-row vs-type="flex" vs-justify="space-around">\n  <vs-col :key="index" v-for="col,index in 3" vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">\n    space-around\n  </vs-col>\n</vs-row>\n\n<vs-row vs-type="flex" vs-justify="space-between">\n  <vs-col :key="index" v-for="col,index in 3" vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">\n    space-between\n  </vs-col>\n</vs-row>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }