var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    {
      attrs: {
        title: "Align Top",
        "code-toggler": "",
        "no-shadow": "",
        "card-border": "",
      },
    },
    [
      _c(
        "div",
        { staticClass: "grid-demo__layout-container--block" },
        [
          _c(
            "vs-row",
            {
              attrs: {
                "vs-align": "flex-start",
                "vs-type": "flex",
                "vs-justify": "center",
                "vs-w": "12",
              },
            },
            _vm._l(4, function (col, index) {
              return _c(
                "vs-col",
                {
                  key: index,
                  staticClass: "sm:p-2 p-4",
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "2",
                  },
                },
                [
                  _c("span", { staticClass: "sm:inline hidden" }, [
                    _vm._v("col - 3"),
                  ]),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<div class="grid-layout-container alignment-block">\n  <vs-row\n    vs-align="flex-start"\n    vs-type="flex" vs-justify="center" vs-w="12">\n    <vs-col :key="index" v-for="col,index in 4" vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">\n      col - 3\n    </vs-col>\n  </vs-row>\n</div>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }