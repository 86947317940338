var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Flex Order", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "In some cases, we want to order the elements to our liking. To do this, use the directive "
        ),
        _c("code", [_vm._v("vs-order")]),
        _vm._v(
          " that has a parameter you just have to pass the number in which we want to order the "
        ),
        _c("code", [_vm._v("vs-col")]),
        _vm._v(" with respect to his brothers "),
        _c("code", [_vm._v("vs-col")]),
      ]),
      _c(
        "div",
        { staticClass: "grid-demo__layout-container" },
        [
          _c(
            "vs-row",
            { attrs: { "vs-type": "flex" } },
            [
              _c(
                "vs-col",
                {
                  staticClass: "sm:p-2 p-4",
                  attrs: {
                    "vs-order": "3",
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "3",
                  },
                },
                [
                  _c("span", { staticClass: "sm:inline hidden" }, [
                    _vm._v("1 / order "),
                  ]),
                  _c("span", [_vm._v("3")]),
                ]
              ),
              _c(
                "vs-col",
                {
                  staticClass: "sm:p-2 p-4",
                  attrs: {
                    "vs-order": "1",
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "3",
                  },
                },
                [
                  _c("span", { staticClass: "sm:inline hidden" }, [
                    _vm._v("2 / order "),
                  ]),
                  _c("span", [_vm._v("1")]),
                ]
              ),
              _c(
                "vs-col",
                {
                  staticClass: "sm:p-2 p-4",
                  attrs: {
                    "vs-order": "4",
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "3",
                  },
                },
                [
                  _c("span", { staticClass: "sm:inline hidden" }, [
                    _vm._v("3 / order "),
                  ]),
                  _c("span", [_vm._v("4")]),
                ]
              ),
              _c(
                "vs-col",
                {
                  staticClass: "sm:p-2 p-4",
                  attrs: {
                    "vs-order": "2",
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "3",
                  },
                },
                [
                  _c("span", { staticClass: "sm:inline hidden" }, [
                    _vm._v("4 / order "),
                  ]),
                  _c("span", [_vm._v("2")]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<vs-row vs-type="flex">\n  <vs-col\n    vs-order="3"\n    vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">\n      1 / order 3\n  </vs-col>\n  <vs-col\n    vs-order="1"\n    vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">\n      2 / order 1\n  </vs-col>\n  <vs-col\n    vs-order="4"\n    vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">\n      3 / order 4\n  </vs-col>\n  <vs-col\n    vs-order="2"\n    vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">\n      4 / order 2\n  </vs-col>\n</vs-row>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }