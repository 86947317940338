var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Overview" } },
    [
      _c("p", [_vm._v("Following is a brief look at how it works:")]),
      _c("vx-list", { staticClass: "mt-5", attrs: { list: _vm.list } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }