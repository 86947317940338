var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "vuexy-grid-demo" } },
    [
      _c("grid-overview"),
      _c("grid-design-concept"),
      _c("grid-column-offset"),
      _c("grid-flex-layout"),
      _c("grid-flex-alignment"),
      _c("grid-flex-order"),
      _c("grid-responsive-layout"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }